/* Scrollbar Track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.7);  /* Change color and add transparency */
  border-radius: 10px; /* Rounded scrollbar */
}

/* Scrollbar Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.7);  /* Change color and add transparency on hover */
}

/* Scrollbar Track Background */
::-webkit-scrollbar-track {
  background: #000;  /* Black background for the track */
  border-radius: 10px;   /* Rounded track */
}
